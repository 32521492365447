import React from 'react'
import { graphql } from 'gatsby'
import find from 'lodash/find'
import { Flex, Image, Text, Stack, Box, Heading, SectionHeading, Button } from '@pw/ui'

import { Layout, NewsletterForm } from '../components/Layout'
import Section from '../components/Layout/Section'
import RowOfPosts from '../components/Posts/Row'
import PostsGrid from '../components/Posts/Grid'
import brittneyPhoto from '../images/homepage_brittney.jpg'
import suitcasesPhoto from '../images/suitcases.jpg'

export const IndexPage = ({ data, location }) => {
  const travelPosts = data?.travelPosts?.edges || []
  const eatPosts = data?.eatPosts?.edges || []
  const sweatPosts = data?.sweatPosts?.edges || []
  const relaxPosts = data?.relaxPosts?.edges || []
  const sleepPosts = data?.sleepPosts?.edges || []
  const verticals = data?.allContentfulVerticals?.edges || []
  const wellnessTravelVertical = find(
    verticals,
    ({ node }) => node.slug === 'wellness-travel',
    {},
  )
  const otherVertical = {
    node: wellnessTravelVertical?.node?.stickyPost || travelPosts?.slice(0, 1)?.node,
  }

  const topPicksToday = [
    // ...travelPosts.slice(0, 1),
    ...eatPosts.slice(0, 1),
    ...sweatPosts.slice(0, 1),
    ...relaxPosts.slice(0, 1),
    ...sleepPosts.slice(0, 1),
  ]
    .filter(Boolean)
    .slice(0, 3)

  const learnAboutTravelPosts = [
    {
      node: {
        ...wellnessTravelVertical.node,
        title: `What is ${wellnessTravelVertical.node.title}?`,
        customPhoto: suitcasesPhoto,
      },
    },
    otherVertical,
    ...travelPosts.slice(0, 0),
  ]

  return (
    <Layout
      header="full"
      location={location}
      seo={{ postTitle: 'A Wellness Travel Blog', title: 'Passport to Wellness' }}
    >
      <Section py={{ base: 0, md: 20 }} pt={{ base: 1, md: 10 }}>
        <Heading
          as="h2"
          fontSize={{ base: '3xl', md: '5xl' }}
          py="4"
          mb="0"
          textAlign="center"
          fontWeight="medium"
          color="gray.800"
        >
          <Box fontWeight="normal" fontSize="70%" fontWeight="300" pt="4">
            Welcome to
          </Box>
          <Box fontWeight="700" letterSpacing="2px">
            Passport to Wellness
          </Box>

          <Box mx="auto" my="4" height="4px" width="100px" bg="primaryColor" />
        </Heading>

        <Text
          p="0"
          px={{ base: 10, md: 0 }}
          maxWidth="700px"
          margin="0 auto"
          textAlign="center"
          color="gray.800"
          fontSize="xl"
        >
          A new website for wellness travel around the world. Find a cure for the common
          vacation with practical wellness travel tips, guides, and inspiration.
        </Text>

        <Box display={{ base: 'flex', md: 'none' }} width="100%" pb="6">
          <Button
            displayType="primary"
            mt="5"
            mx="auto"
            fontWeight="medium"
            to="/wellness-travel"
            textTransform="uppercase"
            fontWeight="600"
          >
            Learn About Wellness Travel
          </Button>
        </Box>
      </Section>

      <Flex
        bg="white"
        w="100%"
        overflow="hidden"
        alignItems="center"
        flexDirection={{ base: 'column', md: 'row' }}
        display={{ base: 'none', md: 'flex' }}
      >
        <Box
          alignSelf="stretch"
          justifyContent="stretch"
          flex="1"
          bg="gray.50"
          w="100%"
          maxW={{ md: '50%' }}
          maxH="600px"
          minH="600px"
        >
          <Image
            src={brittneyPhoto}
            minH="600px"
            objectFit="cover"
            backgroundPosition="center"
          />
        </Box>

        <Box flex="1" bg="white" w="100%" maxW={{ base: '100%', md: '50%' }}>
          <Box w="100%" maxW={{ md: '600px' }}>
            <Box
              borderColor="rgba(255, 255, 255, 0.6)"
              borderWidth="2px"
              position="relative"
              left={{ base: 'inherit', md: '-100px ' }}
              p="2px"
            >
              <Box position="relative" zIndex="2" p="10" bg="white">
                <Heading
                  as="h2"
                  pt="2"
                  pb="4"
                  color="gray.600"
                  fontWeight="400"
                  textTransform="none"
                  fontFamily="body"
                  fontSize="4xl"
                >
                  I'm Brittney Ayers.
                </Heading>

                <Stack spacing="4" color="gray.500" fontSize="lg">
                  <Text>
                    I’m an American girl with wellness wanderlust — obsessed with finding
                    creative ways to make wellness travel accessible.
                  </Text>

                  <Text>
                    I seek out affordable retreats in offbeat destinations throughout the
                    world and create my own wellness weekends in health-centric cities
                    across the USA, yet I also embrace health-focused “staycations” closer
                    to home.
                  </Text>

                  <Text>
                    Through Passport to Wellness, I hope to help others improve their
                    health through travel. Together we’ll chase zen and find wellness
                    between the pages of our passports.
                  </Text>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Section bg="green.100" display={{ base: 'none', md: 'block' }}>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" py="10">
          {/* <Heading as="h5" pt="4" fontWeight="100" fontSize="xl">
            Get the Latest
          </Heading>
          <Heading as="h2" pb="4" fontWeight="400" fontSize="4xl">
            Sign up for my Newsletter
          </Heading> */}

          <SectionHeading
            first="Get the Latest"
            second="Sign up for my newsletter"
            size="md"
          />

          <Box py="4">
            <NewsletterForm />
          </Box>
        </Flex>
      </Section>

      <Section
        bg={{ base: 'white', md: 'beige' }}
        display={{ base: 'none', md: 'block' }}
        py={{ base: 5, md: 10 }}
        px={{ base: 5, md: 0 }}
        childProps={{ maxWidth: '900px' }}
      >
        <SectionHeading first="Learn About" second="Wellness Travel" size="lg" />

        <RowOfPosts hideCategory posts={learnAboutTravelPosts} mb="10" />
      </Section>

      {/* <Section bg="green.100" display={{ base: 'none', md: 'block' }} my="10">
        <Flex alignItems="center" justifyContent="center" flexDirection="column" py="10">
          <SectionHeading second="Find What You are looking for" size="md" />

          <Box py="4">
            <NewsletterForm />
          </Box>
        </Flex>
      </Section> */}

      <Section display={{ base: 'none', md: 'block' }} py="16" pt="8">
        <SectionHeading first="Featured" second="From The Blog" size="md" />

        <PostsGrid hideCategory={false} rowSize="3" posts={topPicksToday} />

        <Flex alignItems="center" justifyContent="center" p="10" width="100%">
          <Button displayType="primary" to="/blog/page/1">
            View More Posts
          </Button>
        </Flex>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(limit: 5, sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          ...postFields
        }
      }
    }

    allContentfulVerticals {
      edges {
        node {
          id
          slug
          title: name
          heroImage: mainPhoto {
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
          stickyPost {
            ...postFields
          }
        }
      }
    }

    travelPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "travel" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    sleepPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "sleep" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    eatPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "eat" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    sweatPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "sweat" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }

    relaxPosts: allContentfulBlogPost(
      limit: 5
      filter: { category: { slug: { eq: "relax" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
        }
      }
    }
  }
`
