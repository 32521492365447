import React from 'react'
import { Box } from '@pw/ui'

import { Container } from './'

export const Section = ({ children, childProps = {}, ...props }) => (
  <Box bg="white" py={10} fontSize="md" {...props}>
    <Container maxWidth="1200px" {...childProps}>
      {children}
    </Container>
  </Box>
)

export default Section
