import React from 'react'
import { Grid } from '@pw/ui'

import PostBox from './Box'

export const RowOfPosts = ({
  posts,
  hideCategory = false,
  templateColumns = {},
  postBoxProps = {},
  ...props
}) => (
  <Grid
    templateColumns={{
      base: '1fr',
      sm: '1fr 1fr',
      md: `repeat(${posts.length}, 1fr)`,
      ...templateColumns,
    }}
    gap={6}
    {...props}
  >
    {posts.map((post) => (
      <PostBox
        post={post?.node || post}
        key={post?.node?.id || post?.id}
        hideCategory={hideCategory}
        {...postBoxProps}
      />
    ))}
  </Grid>
)

export default RowOfPosts
